@media only screen and (min-width: 1441px) {
  .page {
    width: 60%;

    [page-style=wide] & {
      width: 64%;
    }
  }
  
  aside {
    width: 20%;

    [page-style=wide] & {
      width: 18%;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .page {
    width: 56%;

    [page-style=wide] & {
      width: 60%;
    }
  }

  aside {
    width: 22%;

    [page-style=wide] & {
      width: 20%;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .page {
    width: 52%;

    [page-style=wide] & {
      width: 56%;
    }
  }

  aside {
    width: 24%;

    [page-style=wide] & {
      width: 22%;
    }
  }

  #header-desktop .header-wrapper {
    padding-right: 1rem;
  }

  .search-dropdown.desktop {
    right: 1rem;
  }
}

@media only screen and (max-width: 960px) {
  aside {
    display: none!important;
  }

  #toc-static {
    display: block;
  }

  .page {
    width: 80%!important;
  }

  #header-desktop .header-wrapper {
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 680px) {
  #header-desktop {
    display: none;
  }

  #header-mobile {
    display: block;
  }

  body.blur {
    overflow: hidden;
  }

  .page {
    width: 100%!important;

    .content {
      [id] {
        [header-mobile=normal] & {
          scroll-margin-top: 0;
        }
      }
    }

    .categories-card {
      .card-item {
        width: 100%;
      }
    }
  }
  
  footer{
    .footer-container {
      font-size: .618rem;
    }
  }
}

@media only print {
  @page {
    size: A4 portrait;
    margin: 1.27cm;
  }

  .page {
    width: 100%!important;
    padding-top: 0!important;

    .single-title,
    .single-subtitle,
    .post-meta {
      text-align: center;
    }

    h1:not(.single-title),
    .page-break-before {
      page-break-before: always;
    }
  
    .page-break-after {
      page-break-after: always;
    }
  }

  // TODO add common class for all elements
  header,
  footer .footer-line:not(.powered):not(.copyright),
  #toc-auto,
  .post-info-share,
  .post-info-more section:last-child,
  .post-nav,
  #fixed-buttons,
  #comments {
    display: none!important;
  }
}

